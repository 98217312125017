import * as React from 'react';

const HeroBanner: React.FC = () => {
	return (
		<section className="bg-[url('../images/hero-banner.png')] bg-cover">
		<div className="container mx-auto my-0 w-full pt-[64px] pb-[80px] ">
			<h1 className="w-full text-left font-dinblack text-white text-[60px] tracking-[4.5px] max-md:text-center max-md:text-[30px] max-md:leading-[36px]">
			JOIN THE TEAM
			</h1>

		</div>
</section>
	);
};

export default HeroBanner;
